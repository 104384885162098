import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p><img parentName="p" {...{
        "src": "https://emosonic.com/mp3/rpm-blocks-logo1.png",
        "alt": "Logo"
      }}></img>{` `}{` `}{`  `}{` `}{`  `}{` `}{`  PBase-Foundation `}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      